<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <img src="../assets/logo.png" alt="" class="">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-container">
          <p class="mt-5">Valitse päättymispäivä</p>

          <Datepicker
              :class="[errors.indexOf('continue_order') != -1 ? 'error' : '']"
              v-model="continueOrder"
              placeholder="dd.mm.yyyy"
              id="continue_order"
              format="dd.MM.yyyy"
              autoApply
              hideOffsetDates
              closeOnScroll
              :minDate="continueOrder"
              :disabledWeekDays="[6, 0]"
              :enableTimePicker="false"
              :clearable="false"
              :disabledDates="disabledDates"
              @update:modelValue="calculatePrice"
          />

          <p class="mt-3 mb-4">Kokonaismäärä <b>{{price}}&euro;</b></p>

          <div class="stepNav d-flex justify-content-between">
            <button type="button" class="btn btn-primary" @click="saveOrderChanges">Suorita Maksu</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import axios from "axios";
import moment from "moment";

export default {
  name: 'Continue',

  setup () {
    const store             = useStore();
    const route             = useRoute();
    const loading           = ref(true);
    const errors            = ref([]);
    const continueOrder     = ref('');
    const orderDetail       = ref('');
    const price             = ref(0);
    const days              = ref(0);

    const getOrderDetails = async () => {
      let data = {
        method: "GET",
        url: process.env.VUE_APP_API_URL + "/wp-json/api/boxes/" + route.params.id,
      };

      const res = await axios(data);

      if (res) {
        loading.value = false;
        orderDetail.value = res.data;
        continueOrder.value = res.data.details.pickup_date;
      }
    };
    const calculatePrice = () => {
      continueOrder.value = moment(continueOrder.value).format('YYYY-MM-DD');
      const oneDay        = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
      const firstDate     = new Date(orderDetail.value.details.pickup_date);
      const secondDate    = new Date(continueOrder.value);
      const diffDays      = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      days.value          = diffDays;
      price.value         = Math.round((Number(orderDetail.value.details.number_of_boxes) * Number(priceDetails.value.rental_box_price)) * Number(diffDays));
    };
    const saveOrderChanges = async () => {
      const data = {
        id: orderDetail.value.id,
        orderNumber: orderDetail.value.order_number,
        returnUrl: 'https://www.muuttotarjous.fi/muuttolaatikot/?return-from-pay-page',
        price: parseInt(orderDetail.value.price),
        boxes_price: parseInt(orderDetail.value.boxes_price),
        continue_boxes_price: price.value,
        continue_boxes_date: continueOrder.value,
        days: days.value,
        firstName: orderDetail.value.first_name,
        lastName: orderDetail.value.last_name,
        email: orderDetail.value.email,
        start_location: orderDetail.value.details.destination_address,
        payment_method: orderDetail.value.payment_method,
        type: 'boxes',
      };

      const res = await axios.post(process.env.VUE_APP_API_URL + '/wp-json/api/boxes/save-continue-order',{data: data});

      if (res.data){
        window.location.href = res.data.success_url;
      }
    };

    const priceDetails    = computed(() => store.getters.getContinuePriceDetails);
    const disabledDates   = computed(() => {
      const dates = [];

      store.getters.getBlockedDates.map((item) => {
        item.block ? dates.push(new Date(item.date)) : '';
      });

      return dates
    });
    const blockedDates    = computed(() => store.getters.getBlockedDates);

    onMounted(() => {
      getOrderDetails();
      store.dispatch("getBlockedDates");
      store.dispatch("getContinuePriceDetails");
    });

    return {
      loading,
      errors,
      priceDetails,
      disabledDates,
      blockedDates,
      continueOrder,
      price,
      getOrderDetails,
      calculatePrice,
      saveOrderChanges,
    }
  }

}
</script>
